
import UserList from '@/components/admin/UserList.vue';
import { managementMixin } from '@/mixins/managementMixin';
import { defineComponent, PropType } from 'vue';
import { EditableUser, Organizer, School, errorPrepend } from '@/models';
import { confirmDelete, showError } from '@/sweetalert2/templates.ts';
import Swal from '@/sweetalert2';
import EditSchoolModal from '@/components/admin/EditSchoolModal.vue';

export default defineComponent({
  name: 'OrganizerManagement',
  components: { EditSchoolModal, UserList },
  mixins: [managementMixin],
  emits: ['deleteSchool', 'updatedSchool'],
  data () {
    return {
      organizers: [] as Organizer[]
    };
  },
  async mounted () {
    this.organizers = await Organizer.getAll(this.school.id);
    this.organizers.push({ id: -1 } as Organizer);
  },
  props: {
    school: { type: Object as PropType<School>, required: true }
  },
  methods: {
    saveOrganizer (organizer: EditableUser) {
      if (organizer.id !== -1) {
        this.updateOrganizer(organizer);
      } else {
        this.createOrganizer(organizer);
      }
    },
    async refreshOrganizer (organizer: EditableUser) {
      if (organizer.id === -1) {
        this.removeValues(organizer);
        return;
      }
      try {
        const refreshedOrganizer = await Organizer.getById(organizer.id);
        this.removeItem(this.organizers, organizer);
        this.addItem(this.organizers, refreshedOrganizer);
      } catch (e) {
        await showError(this.$t("messages.no organizer load") + errorPrepend());
      }
    },
    async deleteOrganizer (organizer: EditableUser) {
      try {
        await Organizer.delete(organizer.id);
        this.removeItem(this.organizers, organizer);
      } catch (e) {
        await showError(this.$t("messages.error del organizer") + errorPrepend());
      }
    },
    async updateOrganizer (organizer: EditableUser) {
      try {
        await Organizer.update(organizer);
      } catch (e) {
        await showError(this.$t("messages.no organizer change") + errorPrepend());
      }
    },
    async createOrganizer (organizer: EditableUser) {
      try {
        await Organizer.create([{ ...organizer, schoolId: this.school.id }]);
        const allOrganizers = await Organizer.getAll(this.school.id);
        const newOrganizer = allOrganizers.find(o1 => !this.organizers.some(o2 => o2.id === o1.id));
        if (newOrganizer) {
          this.addItem(this.organizers, newOrganizer);
          newOrganizer.password = organizer.password;
          this.removeValues(organizer);
        }
      } catch (e) {
        await showError(this.$t("messages.no organizer add") + errorPrepend());
      }
    },
    async deleteSchool () {
      const result = await confirmDelete(this.$t("messages.Delete?"),
        `${this.$t("messages.del school")} (${this.school.name}) ${this.$t("messages.no return")}`);
      if (result.isDismissed) {
        return;
      }
      this.$emit('deleteSchool', this.school);
    },
    showSchool () {
      Swal.fire({
        title: this.$t("messages.school info"),
        html: `<p>${this.$t("messages.name")}: ${this.school.name}</p><p>ID: ${this.school.id}</p><p>${this.$t("messages.address")}: ${this.school.address}</p>`
      });
    },
    async updateSchool (school: School) {
      this.$emit('updatedSchool', school);
    }
  },
  computed: {
    filteredOrganizers (): Organizer[] {
      const terms = this.search.toLowerCase().split(' ');
      const organizers = this.organizers;
      return organizers.filter((o: EditableUser) =>
        terms.reduce((a: boolean, b: string) => a && (o.id === -1 || o.firstname.toLowerCase().includes(b) || o.lastname.toLowerCase().includes(b)), true));
    }
  }
});
