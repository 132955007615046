
import { defineComponent } from 'vue';
import Modal from '@/components/common/Modal.vue';
import { School } from '@/models';

export default defineComponent({
  name: 'NewSchoolModal',
  components: { Modal },
  emits: ['newSchool'],
  methods: {
    saveChanges () {
      this.$emit('newSchool', this.newSchool);
      (this.$refs.modal as typeof Modal).closeModal();
    }
  },
  data () {
    return {
      newSchool: {
        name: '',
        address: ''
      } as School
    };
  }
});
