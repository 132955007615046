
import { defineComponent } from 'vue';
import OrganizerManagement from '@/components/admin/OrganizerManagement.vue';
import { CommonError, School, errorPrepend } from '@/models';
import { downloadMixin } from '@/mixins/downloadMixin';
import { showError } from '@/sweetalert2/templates.ts';
import NewSchoolModal from '@/components/admin/NewSchoolModal.vue';

export default defineComponent({
  name: 'Schoolmanagement',
  components: { NewSchoolModal, OrganizerManagement },
  mixins: [downloadMixin],
  async mounted () {
    try {
      this.schools = await School.getAll();
    } catch (e) {
      await showError(this.$t("messages.no data load") + errorPrepend());
    }
  },
  data () {
    return {
      schools: [] as School[],
      search: '',
      passwordLength: 8,
      warnOnDelete: true
    };
  },
  methods: {
    async createSchool (school: School) {
      try {
        await School.create(school);
        this.schools = await School.getAll();
      } catch (e) {
        await showError(this.$t("messages.no school add") + errorPrepend());
      }
    },
    async deleteSchool (school: School) {
      try {
        await School.delete(school.id);
        this.schools = await School.getAll();
      } catch (e) {
        await showError(this.$t("messages.error del school") + errorPrepend());
      }
    },
    async updateSchool (school: School) {
      try {
        await School.update(school);
        this.schools = await School.getAll();
      } catch (e) {
        await showError(this.$t("messages.error school change") + errorPrepend());
      }
    }
  }
});
