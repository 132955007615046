
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/common/Modal.vue';
import { School } from '@/models';

export default defineComponent({
  name: 'EditSchoolModal',
  components: { Modal },
  props: {
    school: { type: Object as PropType<School>, required: true }
  },
  emits: ['saveChanges'],
  methods: {
    saveChanges () {
      this.$emit('saveChanges', this.newSchool);
      (this.$refs.modal as typeof Modal).closeModal();
    }
  },
  data () {
    return {
      newSchool: {
        name: '',
        address: ''
      } as School
    };
  },
  watch: {
    school: {
      handler (newVal: School) {
        this.newSchool = { ...newVal };
      },
      deep: true,
      immediate: true
    }
  }
});
